exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-bankkonto-tsx": () => import("./../../../src/pages/bankkonto.tsx" /* webpackChunkName: "component---src-pages-bankkonto-tsx" */),
  "component---src-pages-beregn-kompensation-tsx": () => import("./../../../src/pages/beregn-kompensation.tsx" /* webpackChunkName: "component---src-pages-beregn-kompensation-tsx" */),
  "component---src-pages-betingelser-og-vilkaar-tsx": () => import("./../../../src/pages/betingelser-og-vilkaar.tsx" /* webpackChunkName: "component---src-pages-betingelser-og-vilkaar-tsx" */),
  "component---src-pages-dine-rettigheder-tsx": () => import("./../../../src/pages/dine-rettigheder.tsx" /* webpackChunkName: "component---src-pages-dine-rettigheder-tsx" */),
  "component---src-pages-flyselskaber-tsx": () => import("./../../../src/pages/flyselskaber.tsx" /* webpackChunkName: "component---src-pages-flyselskaber-tsx" */),
  "component---src-pages-fuldmagt-tsx": () => import("./../../../src/pages/fuldmagt.tsx" /* webpackChunkName: "component---src-pages-fuldmagt-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-ofte-stillede-spoergsmaal-tsx": () => import("./../../../src/pages/ofte-stillede-spoergsmaal.tsx" /* webpackChunkName: "component---src-pages-ofte-stillede-spoergsmaal-tsx" */),
  "component---src-pages-om-os-tsx": () => import("./../../../src/pages/om-os.tsx" /* webpackChunkName: "component---src-pages-om-os-tsx" */),
  "component---src-pages-persondatapolitik-tsx": () => import("./../../../src/pages/persondatapolitik.tsx" /* webpackChunkName: "component---src-pages-persondatapolitik-tsx" */),
  "component---src-pages-regler-ved-aflyst-fly-tsx": () => import("./../../../src/pages/regler-ved-aflyst-fly.tsx" /* webpackChunkName: "component---src-pages-regler-ved-aflyst-fly-tsx" */),
  "component---src-pages-regler-ved-forsinket-fly-tsx": () => import("./../../../src/pages/regler-ved-forsinket-fly.tsx" /* webpackChunkName: "component---src-pages-regler-ved-forsinket-fly-tsx" */),
  "component---src-pages-regler-ved-mistet-forbindelsesfly-tsx": () => import("./../../../src/pages/regler-ved-mistet-forbindelsesfly.tsx" /* webpackChunkName: "component---src-pages-regler-ved-mistet-forbindelsesfly-tsx" */),
  "component---src-pages-regler-ved-overbooket-fly-tsx": () => import("./../../../src/pages/regler-ved-overbooket-fly.tsx" /* webpackChunkName: "component---src-pages-regler-ved-overbooket-fly-tsx" */),
  "component---src-pages-tilfredshed-tsx": () => import("./../../../src/pages/tilfredshed.tsx" /* webpackChunkName: "component---src-pages-tilfredshed-tsx" */),
  "component---src-pages-web-2-media-tsx": () => import("./../../../src/pages/web2media.tsx" /* webpackChunkName: "component---src-pages-web-2-media-tsx" */)
}

